import { Outlet } from "react-router-dom";
import {
  BDEConvert,
  Billing,
  CheckRate,
  CounterFeedback,
  CustomerFeedback,
  SaleReturn,
  CustomerVOC,
} from "../../../pages";
import routesMap from "../../../routeControl/userRouteMap";
import { baseRoutes } from "../../../helpers/baseRoutes";

export default function route() {
  return [
    {
      path: baseRoutes.userBaseRoutes,
      name: "Counter",
      key: baseRoutes.userBaseRoutes,
      commonRoute: true,
      belongsToSidebar: true,
      private: true,
      // element: <Billing />,BILLING_ROUTE
      element: <Outlet />,
      children: [
        {
          path: routesMap.BILLING.path,
          name: "Counter Sale",
          key: routesMap.BILLING.path,
          commonRoute: true,
          belongsToSidebar: true,
          private: true,
          element: <Billing />,
        },
        {
          path: routesMap.SALERETURN.path,
          name: "Sale Return",
          key: routesMap.SALERETURN.path,
          belongsToSidebar: true,
          disabled: true,
          commonRoute: true,
          private: true,
          element: <SaleReturn />,
        },
        {
          path: routesMap.CHECKRATE.path,
          name: "Check Rate",
          key: routesMap.CHECKRATE.path,
          belongsToSidebar: true,
          commonRoute: true,
          private: true,
          element: <CheckRate />,
        },
        {
          path: routesMap.BDEConvert.path,
          name: "BDE Convert",
          key: routesMap.BDEConvert.path,
          belongsToSidebar: true,
          commonRoute: true,
          private: true,
          element: <BDEConvert />,
        },
        {
          path: routesMap.COUNTERFEEDBACK.path,
          name: "Counter Feedback",
          key: routesMap.COUNTERFEEDBACK.path,
          belongsToSidebar: false,
          commonRoute: true,
          private: true,
          element: <CustomerFeedback />,
        },
        {
          path: routesMap.COUNTERFEEDBACKACTION.path,
          name: "Counter Feedback And Action",
          key: routesMap.COUNTERFEEDBACKACTION.path,
          belongsToSidebar: false,
          commonRoute: true,
          private: true,
          element: <CounterFeedback />,
        },
        {
          path: routesMap.CUSTOMERVOC.path,
          name: "Customer VOC",
          key: routesMap.CUSTOMERVOC.path,
          belongsToSidebar: false,
          commonRoute: true,
          private: true,
          element: <CustomerVOC />,
        },
      ],
    },
  ];
}
