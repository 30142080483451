import { getStoreItemList } from "./Store";

export const CommonGetStoreItemsAPI = async ({
  getByID,
  mode,
  token,
  add,
  update,
}) => {
  try {
    let checkData = await getByID(1).then((personsFromDB) => personsFromDB);
    let payload = {
      location_id: checkData?.location_id,
      wms_store_id: checkData?.wms_store_id,
    };

    let resMode = await mode.getByID(1).then((personFromDB) => personFromDB);

    if (resMode.mode === "onLine") {
      const res = await getStoreItemList(payload, token);

      if (res?.data?.status) {
        if (res?.data?.data) {
          if (!checkData?.storeItem?.length > 0 && !checkData?.id) {
            add({
              wms_store_id: checkData?.wms_store_id,
              storeItem: res?.data?.data,
              location_id: checkData?.location_id,
            });
          } else if (checkData?.id && !checkData?.storeItem?.length > 0) {
            let payload = {
              id: 1,
              wms_store_id: checkData?.wms_store_id,
              storeItem: res?.data?.data,
              location_id: checkData?.location_id,
            };
            update({ ...payload });
          } else {
            let payload = {
              id: 1,
              wms_store_id: checkData?.wms_store_id,
              storeItem: res?.data?.data,
              location_id: checkData?.location_id,
            };
            update({ ...payload });
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};
