/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getSideBarData,
  removeLocalStorageToken,
} from "../../utils/common.util";
import CommonApiCalling from "../../services/commonApiCalling";
import SweetAlert from "../SweetAlert";
import { useIndexedDB } from "react-indexed-db-hook";
import { logger } from "../../utils";
import ModalComponent from "../Modal";
import NoInternetConnection from "../../pages/NoInternetConnection";
import routesMap from "../../routeControl/userRouteMap";
import { Spinner } from "../Antd";
import { baseRoutes } from "../../helpers/baseRoutes";
import ChangePassword from "../ChangePassword";
const Navbar = ({ routes }) => {
  const location = useLocation();
  const { add, clear, getAll } = useIndexedDB("headerActiveKey");
  const [syncData, setSYNCData] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [activePath, setActivePath] = useState("");
  const [modalState, setModalState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [state, setState] = useState({
    menu: [],
    current: location.pathname,
  });

  const handleLogout = () => {
    removeLocalStorageToken();
    navigate(baseRoutes?.defaultBaseRoutes);
  };

  const onConfirmAlert = () => {
    handleLogout();
    setIsAlertVisible(false);
    clear();
    return true;
  };

  const handleCloseModal = () => {
    setChangePasswordModal(false);
  }

  const logoutArr = [
    // {
    //   key: "resetSync",
    //   value: "Reset Sync",
    //   path: "/resetSync",
    // },
    {
      key: "updateSync",
      value: "Update Sync",
      path: "#",
      onClick: () => {
        setLoading(true);
        setSYNCData(true);
      },
    },
    {
      key: "changePassword",
      value : "Change Password",
      path : "#",
      onClick : () => {
        setChangePasswordModal(true);
      }
    },
    // {
    //   key: "pendingSyncAmt",
    //   value: "Pending Sync Amount",
    //   path: "/pendingSyncAmt",
    // },
    // {
    //   key: "theme",
    //   value: "Theme",
    //   path: "/theme",
    // },
    // {
    //   key: "resetSwapDevice",
    //   value: "Reset Swap Device",
    //   path: "/resetSwapDevice",
    // },
    {
      key: "logout",
      value: "Logout",
      path: "#",
      onClick: () => setIsAlertVisible(true),
    },
  ];

  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [logoutDropdownOpen, setLogoutDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const handleItemClick = (item) => {
    if (item.children?.length > 0) {
      setDropdownOpen(dropdownOpen === item ? null : item);
    } else {
      setActiveItem(item);
      setDropdownOpen(null);
      navigate(item.path);
    }
  };

  const handleChildItemClick = (parentItem, child) => {
    clear();
    add({
      parentActiveRoute: parentItem?.label,
      activeRoute: child.path,
    });
    setActiveItem({ parent: parentItem, child });
    setDropdownOpen(null);
    if (navigator?.onLine || child.path === routesMap?.BILLING.path) {
      navigate(child.path);
    } else {
      setModalState(true);
    }
  };

  const handleLogoutClick = () => {
    setLogoutDropdownOpen(!logoutDropdownOpen);
  };

  const handleLogoutChildClick = (child) => {
    setLogoutDropdownOpen(false);
    if (child.path !== "#") {
      navigate(child.path);
    } else {
      logger("Logout clicked");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownOpen && !event.target.closest(".dropdown")) {
        setDropdownOpen(null);
      }
      if (logoutDropdownOpen && !event.target.closest(".logout-dropdown")) {
        setLogoutDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownOpen, logoutDropdownOpen]);

  useEffect(() => {
    if (routes instanceof Array) {
      setState({ ...state, menu: getSideBarData(routes) });
    }
  }, [routes]);

  const getActivePath = async () => {
    let data = await getAll().then((personsFromDB) => {
      return personsFromDB[0];
    });
    setActivePath(data);
  };

  useEffect(() => {
    if (location?.pathname) {
      getActivePath();
    }
  }, [location]);

  return (
    <>
      <CommonApiCalling
        setSYNCData={setSYNCData}
        syncData={syncData}
        setLoading={setLoading}
      />
      {loading && (
        <div className="preloader">
          <Spinner />
        </div>
      )}
      <nav className="navbar">
        <div className="nav-left">
          <ul className="nav-items gap-3">
            {state?.menu.map((item, index) => (
              <li
                key={index}
                className={
                  activePath?.parentActiveRoute === item?.label ? "active" : ""
                }
              >
                {item.children?.length > 0 ? (
                  <div
                    className="dropdown"
                    onClick={() => handleItemClick(item)}
                  >
                    <span>{item.label}</span>
                    {dropdownOpen === item && (
                      <ul className="dropdown-menu">
                        {item.children.map((child, childIndex) => (
                          <li
                            key={childIndex}
                            className={
                              child?.path === activePath?.activeRoute
                                ? "active"
                                : ""
                            }
                          >
                            <span
                              onClick={() => handleChildItemClick(item, child)}
                            >
                              {child.label}
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ) : (
                  <span onClick={() => handleItemClick(item)}>
                    {item.label}
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="nav-right">
          <ul className="nav-items">
            <li className="logout-dropdown" onClick={handleLogoutClick}>
              <span>Window</span>
              {logoutDropdownOpen && (
                <ul className="logout-dropdown-menu">
                  {logoutArr.map((child, index) => (
                    <li
                      key={index}
                      className={activeItem?.child === child ? "active" : ""}
                      onClick={(e) => {
                        if (child?.path === "#") {
                          child?.onClick();
                        }
                      }}
                    >
                      <span onClick={() => handleLogoutChildClick(child)}>
                        {child.value}
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
        </div>
      </nav>

      <SweetAlert
        reverseButtons
        title="Logout"
        text="Are you sure, want to logout?"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        cancelButtonText="No"
        confirmButtonText="Yes"
        setIsAlertVisible={setIsAlertVisible}
        showLoaderOnConfirm
        onConfirmAlert={onConfirmAlert}
      />

      <ModalComponent
        show={modalState}
        size="xl"
        modalHeader="NO INTERNET!"
        onHandleCancel={() => setModalState(false)}
      >
        <NoInternetConnection handleCloseModal={() => setModalState(false)} />
      </ModalComponent>

      <ModalComponent
        modalHeader={"Change Password Form"}
        // size="lg"
        show={changePasswordModal}
        onHandleCancel={handleCloseModal}
      >
        <ChangePassword setChangePasswordModal={setChangePasswordModal}/>
      </ModalComponent>
    </>
  );
};

export default Navbar;
