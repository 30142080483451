import React from "react";

const SaleReturnInvoiceFormatter = ({ id, printInvoiceData }) => {
  const invoiceData = [
    {
      itemName: "YARDLEY GOLD TALC 100 GM",
      qty: 1.0,
      unit: "No",
      rate: 135.0,
      amt: 135.0,
      discount: 5.0,
      netAmt: 130.0,
    },
    {
      itemName: "AMUL BUTTER GARLIC & HERBS 100.00 GM PACKET",
      qty: 1.0,
      unit: "No",
      rate: 59.0,
      amt: 59.0,
      discount: 0.0,
      netAmt: 59.0,
    },
    {
      itemName: "CATCH RED CHILLI POWDER 200 GM",
      qty: 1.0,
      unit: "No",
      rate: 70.0,
      amt: 70.0,
      discount: 8.0,
      netAmt: 62.0,
    },
    {
      itemName: "PEPSI SOFT DRINK 1Ltr",
      qty: 1.0,
      unit: "No",
      rate: 80.0,
      amt: 80.0,
      discount: 15.0,
      netAmt: 65.0,
    },
    {
      itemName: "DETTOL SKIN CARE SOAP",
      qty: 1.0,
      unit: "No",
      rate: 40.0,
      amt: 40.0,
      discount: 0.0,
      netAmt: 40.0,
    },
  ];

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    for (let i = 0; i < invoiceData.length; i++) {
      totalAmount += invoiceData[i].netAmt;
    }
    return parseFloat(totalAmount).toFixed(2);
  };
  const getDiscount = (value) => {
    let res = parseFloat(value?.mrp) - parseFloat(value?.orp);
    let finalresult = res * parseFloat(value?.updatedQty || value?.quantity);
    return finalresult?.toFixed(2);
  };

  return (
    <>
      <div id={id} className="salereturn-invoice d-none">
        <div className="bill">
          <div className="brand">ONDOOR CONCEPTS PVT LTD</div>
          <div className="address">{printInvoiceData?.store_address || ""}</div>
          <div className="bill-details">
            <div className="flex justify-between">
              <div className="gst-text">CIN:U52100MP2014PTC033570</div>
              <div className="gst-text">GSTIN:23AACCO0825C1Z5</div>
            </div>
          </div>
          <div className="bill-tax-head sr-mt-5">SALE RETURN</div>
          <div className="bill-to-head mob-mt-2">
            MOB NO: {printInvoiceData?.mobile_number || "NA"}
          </div>
          <div className="bill-details inv-mb-5">
            <div className="flex justify-between">
              <div className="bill-to-head">
                {printInvoiceData?.sale_return_date || "NA"}
              </div>
              <div className="bill-to-head">
                {printInvoiceData?.sale_return_code || "NA"}
              </div>
            </div>
          </div>
          <table className="table row-mb">
            <tr className="header">
              <th colspan="2">Particular</th>
              <th>Qty</th>
              <th>Unit</th>
              <th>Rate</th>
              <th>Amt</th>
              <th>Disc</th>
              <th>NetAmt</th>
            </tr>
            {printInvoiceData?.saleReturnItem?.map((data, index) => (
              <tr key={index}>
                <td colspan="2">{data.product_name}</td>
                <td className="gst-ml">
                  {parseFloat(data.quantity).toFixed(2)}
                </td>
                <td className="gst-ml">{data.unit}</td>
                <td className="gst-ml">{parseFloat(data.mrp).toFixed(2)}</td>
                <td className="gst-ml">{parseFloat(data.orp).toFixed(2)}</td>
                <td className="gst-ml">{getDiscount(data)}</td>
                <td className="gst-ml">{parseFloat(data.amount).toFixed(2)}</td>
              </tr>
            ))}
            <tr className="total">
              <td className="text-bold-600">
                Items: {printInvoiceData?.saleReturnItem?.length}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colspan="2" className="text-bold-600 white-space">
                Total Amount:
              </td>
              <td className="text-bold-600">
                {parseFloat(printInvoiceData?.total_amount)?.toFixed(2)}
              </td>
            </tr>
          </table>
          <div className="border-bottom">
            <div className="bill-amounts">
              <div className="text-bold-600 payable-size inv-font-family">
                Cash Return:
              </div>
              <div className="text-bold-600 payable-size inv-font-family payable-amt">
                {parseFloat(printInvoiceData?.total_amount)?.toFixed(2)}
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </>
  );
};

export default SaleReturnInvoiceFormatter;
