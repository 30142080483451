import React from 'react'
import { checkValidData } from '../../utils';

const VegLocalPurchaseFormatter = ({ id, printData, identifier }) => {

  // variable for veg local purchase
  let docDate = "";
  let docNo = "";
  let partyName = "";
  let storeName = "";
  let totalAmount = "";
  let itemsData = [];

  // variable for local purchase grocery
  let localCocoName = "";
  let localDocDate = "";
  let localDocNo = "";
  let localSupplier = "";
  let localItems = [];

  if (identifier === "VegPurchase") {
    printData?.map(item => {
      docDate = item?.date;
      docNo = item?.docNo;
      partyName = item?.partyName;
      storeName = item?.storeName;
      totalAmount = item?.amount;
      itemsData.push(item?.itemData);
    });
  } else if (identifier === "localPurchase") {
    printData?.map(item => {
      localCocoName = item?.COCO;
      localDocDate = item?.DocDate;
      localDocNo = item?.DocNo;
      localSupplier = item?.Supplier;
      localItems.push({
        localAmount: item?.Amount,
        localQuantity: item?.Qty,
        localRate: item?.Rate,
        localMrp: item?.MRP,
        localItName: item?.ItName,
        localItId: item?.Itid,
      })
    })
  }

  const calculateLocalTotalAmount = () => {
    let totalNetAmount = 0;
    localItems.forEach(item => {
      totalNetAmount += parseFloat(item.localAmount);
    });
    return totalNetAmount.toFixed(2);
  }

  return (
    <>
      <div id={id} className='veglocal-invoice'>
        <div className='bill'>
          <div className='brand'>ONDOOR CONCEPT LTD.</div>
          <div className="bill-tax-head">
            {identifier === "VegPurchase" ?
              "Veg Local Purchase" :
              identifier === "localPurchase" ?
                "Local Purchase Grocery" : "Veg Dispose"
            }
          </div>
          {identifier === "VegPurchase"
            ?
            <>
              <div className="bill-details">
                <div className="flex justify-center">
                  <div className="sub-brand">Purchase Invoice</div>
                </div>
              </div>
              <div className="bill-details">
                <div className="flex justify-between">
                  <div className="bill-to-head">Invoice No</div>
                  {/* <div className="bill-to-head">88995563210</div> */}
                </div>
              </div>
              <div className="bill-details">
                <div className="flex justify-between">
                  <div className="bill-to-head">Coco: {checkValidData(storeName)} </div>
                  <div className="bill-to-head">Date: {checkValidData(docDate)} </div>
                </div>
              </div>
              <div className="bill-details">
                <div className="flex justify-between">
                  <div className="bill-to-head">Party: {checkValidData(partyName)} </div>
                </div>
              </div>
              <div className="bill-details">
                <div className="flex justify-between">
                  <div className="bill-to-head">DocNo: {checkValidData(docNo)} </div>
                </div>
              </div>
              <table className="table row-mb">
                <tr className="header">
                  <th>SNo</th>
                  <th className="gst-ml">Itid</th>
                  <th className="veg-pl">ItName</th>
                  <th className="veg-pl">Qty</th>
                  <th className="veg-pl"></th>
                  <th className="veg-pl">Rate</th>
                  <th className="gst-ml">Amount</th>
                </tr>
                {itemsData[0]?.map((item, index) => {
                  return (<tr key={index}>
                    <td>1</td>
                    <td>{item?.Itid}</td>
                    <td className="veg-pl">{checkValidData(item?.ItName)}</td>
                    <td className="veg-pl">{checkValidData(item?.Qty)}</td>
                    <td className="veg-pl"></td>
                    <td className="veg-pl">{checkValidData(parseFloat(item?.Rate).toFixed(2))}</td>
                    <td>{checkValidData(parseFloat(item?.Amount).toFixed(2))}</td>
                  </tr>)
                })}
                <tr className="total">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-bold-600">Total</td>
                  <td className="text-end">
                    {checkValidData(parseFloat(totalAmount).toFixed(2))}
                  </td>
                </tr>
              </table>
              <div className="bill-details">
                <div className="flex justify-between">
                  <div className="bill-to-head">Entry By </div>
                </div>
              </div>
              <div className="bill-details">
                <div className="flex justify-between">
                  <div className="bill-to-head">Receiver Sign :  </div>
                  <div className="bill-to-head">Checked By :  </div>
                </div>
              </div>
            </>
            : identifier === "localPurchase" ?
              <>
                <div className="bill-details">
                  <div className="flex justify-center">
                    <div className="sub-brand">Purchase Invoice</div>
                  </div>
                </div>
                <div className="bill-details">
                  <div className="flex justify-between">
                    <div className="bill-to-head">Invoice No</div>
                    {/* <div className="bill-to-head">88995563210</div> */}
                  </div>
                </div>
                <div className="bill-details">
                  <div className="flex justify-between">
                    <div className="bill-to-head">Coco: {checkValidData(localCocoName)} </div>
                    <div className="bill-to-head">Date: {checkValidData(localDocDate)} </div>
                  </div>
                </div>
                <div className="bill-details">
                  <div className="flex justify-between">
                    <div className="bill-to-head">Supplier: {checkValidData(localSupplier)} </div>
                  </div>
                </div>
                <div className="bill-details">
                  <div className="flex justify-between">
                    <div className="bill-to-head">DocNo: {checkValidData(localDocNo)} </div>
                  </div>
                </div>
                <table className="table row-mb">
                  <tr className="header">
                    <th>SNo</th>
                    <th className="gst-ml">Itid</th>
                    <th className="veg-pl">ItName</th>
                    <th className="veg-pl">Qty</th>
                    <th className="veg-pl">MRP</th>
                    <th className="veg-pl">Rate</th>
                    <th className="gst-ml">Amount</th>
                  </tr>
                  {localItems?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{checkValidData(item?.localItId)}</td>
                        <td className="veg-pl">{checkValidData(item?.localItName)}</td>
                        <td className="veg-pl">{checkValidData(item?.localQuantity)}</td>
                        <td className="veg-pl">{checkValidData(item?.localMrp)}</td>
                        <td className="veg-pl">{checkValidData(parseFloat(item?.localRate).toFixed(2))}</td>
                        <td>{checkValidData(parseFloat(item?.localAmount).toFixed(2))}</td>
                      </tr>
                    )
                  })}
                  <tr className="total">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-bold-600">Total</td>
                    <td className="text-end">
                      {calculateLocalTotalAmount()}
                    </td>
                  </tr>
                </table>
                <div className="bill-details">
                  <div className="flex justify-between">
                    <div className="bill-to-head">Entry By </div>
                  </div>
                </div>
                <div className="bill-details">
                  <div className="flex justify-between">
                    <div className="bill-to-head">Receiver Sign :  </div>
                    <div className="bill-to-head">Checked By :  </div>
                  </div>
                </div>
              </> :
              <>
                <div className="bill-details">
                  <div className="flex justify-between">
                    <div className="bill-to-head">Date: 13/04/2024 </div>
                  </div>
                </div>
                <div className="bill-details">
                  <div className="flex justify-between">
                    <div className="bill-to-head">DocNo: 2288446655 </div>
                  </div>
                </div>
                <table className="table row-mb">
                  <tr className="header">
                    <th>SNo</th>
                    <th className="gst-ml">Itid</th>
                    <th className="veg-pl">ItName</th>
                    <th className="veg-pl">Unit</th>
                    <th className="veg-pl">Qty</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>207</td>
                    <td className="veg-pl">Apple 500 GM</td>
                    <td className="veg-pl">KG</td>
                    <td className="veg-pl">3.0</td>
                  </tr>
                  {/* <tr className="total">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-bold-600">Total</td>
                    <td className="text-end">
                      {checkValidData(parseFloat(totalAmount).toFixed(2))}
                    </td>
                  </tr> */}
                </table>
                {/* <div className="bill-details">
                  <div className="flex justify-between">
                    <div className="bill-to-head">Entry By </div>
                  </div>
                </div>
                <div className="bill-details">
                  <div className="flex justify-between">
                    <div className="bill-to-head">Receiver Sign :  </div>
                    <div className="bill-to-head">Checked By :  </div>
                  </div>
                </div> */}
              </>}
          <hr />
        </div>
      </div>
    </>
  )
}

export default VegLocalPurchaseFormatter