import React, { useEffect, useState } from "react";
import { Select as AntSelect, Form, Empty } from "antd";
import config from "../../config";
function CustomSelect({
  uniqueKey = "default-random-key",
  children,
  arrayOfData,
  label = "",
  onSelect,
  callback,
  loading,
  selectedValue,
  onKeyChangeValue = false,
  getBarCodeValue,
  defaultValue,
  resetInput,
  setSelectRef,
  setCustomSearchValue = false,
  ...rest
}) {
  const [qty, setQTY] = useState(false);
  const [value, setValue] = useState([]);
  const [open, setOpen] = useState(false);
  // const selectRef = useRef(null);
  const [emptyElm, setEmptyElm] = useState("");

  const onChangeValue = (val) => {
    let tempArr = val.target.value.trim().split("");
    setValue(tempArr);
    // if (tempArr?.length === 0 || val.target.value === "") {
    //   let dropHide = document.querySelector(".ant-select-dropdown");
    //   setQTY(false);
    //   if (dropHide !== null) {
    //     dropHide?.classList.add("ant-select-dropdown-hidden");
    //     dropHide?.childNodes[0]?.classList.add("d-none");
    //   }
    // }
    //  else
    if (tempArr?.length > 0) {
      setOpen(false);
      let dropHide = document.querySelector(
        `.ant-select-dropdown-${uniqueKey}`
      );
      if (dropHide !== null) {
        dropHide?.childNodes[0]?.classList.remove("d-none");
        // dropHide?.classList.remove("ant-select-dropdown-hidden");
      }
    }
    if (tempArr[0] === "+" || tempArr[0] === "-" || tempArr[0] === 0) {
      setQTY(true);
      setOpen(true);
      setTimeout(() => {
        let dropHide1 = document.querySelector(
          `.ant-select-dropdown-${uniqueKey}`
        );
        setEmptyElm(dropHide1);
      }, 0);
    } else if (val?.code === "Enter") {
      if (getBarCodeValue) {
        getBarCodeValue(val.target.value);
      }
      setOpen(false);
      setQTY(false);
    } else if (tempArr[0] !== "+") {
      setQTY(false);
      setOpen(true);
    } else if (val.target.value === "") {
      setOpen(false);
    }
    if (val?.keyCode === 13) {
      if (value[0] === "+" || value[0] === "-" || value[0] === "0") {
        if (onKeyChangeValue) {
          onKeyChangeValue(value);
        }
        setOpen(false);
        setQTY(false);
      } else {
        setQTY(false);
      }
    }
  };

  const handleChangeSelect = (value) => {
    if (!qty) {
      selectedValue(value);
      setOpen(false);
    }
  };

  useEffect(() => {
    if (emptyElm !== null && emptyElm !== "" && value[0] === "+") {
      emptyElm?.childNodes[0]?.classList.add("d-none");
    }
  }, [emptyElm, value]);

  // useEffect(() => {
  //   if (selectRef && setSelectRef) {
  //     setSelectRef(selectRef);
  //   }
  // }, [selectRef]);

  // Close dropdown when clicking outside
  const handleClickOutside = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.addEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Form.Item className="mb-0" label={label}>
      <AntSelect
        dropdownClassName={`ant-select-dropdown-${uniqueKey}`}
        // ref={selectRef}
        notFoundContent={
          <Empty
            image={`${config.IMAGE_URL}searchNoFound.svg`}
            description="Search Not Found"
          />
        }
        id={`select-${uniqueKey}`}
        size="large"
        lang="en"
        open={open}
        value={defaultValue || undefined}
        loading={loading}
        onKeyDown={(e) => {
          if (e.target.value?.length === 0) {
            if (e.keyCode === 32) {
              e.preventDefault();
            }
          }
        }}
        onKeyUp={onChangeValue}
        {...rest}
        filterOption={(input, option) => {
          let inputVal = input.trim();
          if (setCustomSearchValue) {
            setCustomSearchValue(inputVal);
          }
          if (option?.BCGunStrict) {
            if (option?.BCGunStrict === "0") {
              return (
                (inputVal !== "-" || inputVal !== " ") &&
                option.label?.toLowerCase()?.includes(inputVal?.toLowerCase())
              );
            }
          } else {
            return (
              (inputVal !== "-" || inputVal !== " ") &&
              option.label?.toLowerCase()?.includes(inputVal?.toLowerCase())
            );
          }
        }}
        onChange={onSelect || handleChangeSelect}
        options={
          !qty &&
          arrayOfData?.length > 0 &&
          arrayOfData?.map((item, index) => ({
            value: item?.id,
            label: item?.name,
            BCGunStrict: item?.BCGunStrict ? item?.BCGunStrict : "",
          }))
        }
      />
    </Form.Item>
  );
}

export default CustomSelect;
