import React from "react";
export const UserLogin = React.lazy(() => import("./Login/index.page"));
export const SignupUser = React.lazy(() => import("./Registration/index.page"));
export const Billing = React.lazy(() => import("./Billing/index"));
export const SaleReturn = React.lazy(() => import("./SaleReturn/index"));
export const CheckRate = React.lazy(() => import("./CheckRate/index"));
export const PopSticker = React.lazy(() => import("./PopSticker/index"));
export const Dashboard = React.lazy(() => import("./Dashboard/index"));
export const CustomerFeedback = React.lazy(() =>
  import("./CounterFeedback/index")
);
export const CashHandover = React.lazy(() => import("./CashHandover/index"));
export const OnlineReceiving = React.lazy(() =>
  import("./OnlineReceiving/index")
);
export const WarehouseStockTaking = React.lazy(() =>
  import("./WarehouseStockTaking/index")
);
export const LocalPurchaseGrocery = React.lazy(() =>
  import("./LocalPurchaseGrocery/index")
);
export const VegLocalPurchase = React.lazy(() =>
  import("./VegLocalPurchase/index")
);
export const PosDeviceRegister = React.lazy(() =>
  import("./PosDeviceRegister/index")
);
export const NewTransferModule = React.lazy(() =>
  import("./NewTransferModule/index")
);
export const OfferItemConvert = React.lazy(() =>
  import("./OfferItemConvert/index")
);
export const BDEConvert = React.lazy(() => import("./BdeConvert/index"));
export const CounterFeedback = React.lazy(() =>
  import("./CounterFeedbackAction/index")
);
export const StockTakingApproval = React.lazy(() =>
  import("./StockTakingApproval/index")
);
export const StockTakingCache = React.lazy(() =>
  import("./AnalysisReports/index")
);
export const CustomerVOC = React.lazy(() => import("./CustomerVOC/index"));
export const VegDispose = React.lazy(() => import("./VegDispose/index"));
