const config = {
  NAME_KEY: process.env.REACT_APP_NAME_KEY || "App",
  APP_BASE_URL: process.env.REACT_APP_BASE_URL,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  DEFAULT_LANGUAGE: process.env.REACT_APP_DEFAULT_LANGUAGE || "en",
  IMAGE_URL: process.env.REACT_APP_IMAGE_PATH || "",
  DEFAULT_URL: process.env.REACT_APP_DEFAULT_BASE_PATH || "/",
  MAIN_URL: process.env.REACT_APP_MAIN_BASE_PATH || "/",
  DATE_FORMAT: "YYYY-MM-DD",
  HOST_NAME: process.env.REACT_APP_PROCEDURE_HOST_NAME,
  HOST_OS: process.env.REACT_APP_PROCEDURE_HOST_OS,
  HOST_RID: process.env.REACT_APP_PROCEDURE_HOST_RID,
  HOST_KEY: process.env.REACT_APP_PROCEDURE_HOST_KEY,
  USER_ID: process.env.REACT_APP_EMPLOYEE_ID || "",
  USER_PASSWORD: process.env.REACT_APP_EMPLOYEE_PASSWORD || "",
  WMS_STORE_ID: process.env.REACT_APP_WMS_STORE_ID || "",
};
export default config;
